<template>
  <div class="page add-payment-nicepay more-depth">
    <div class="container">
      <div class="page-body">
        <PaymentKakaoPay :redirect="this.$route.query" />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentKakaoPay from '@/v3/components/resources/payment/PaymentKakaoPay.vue';

export default {
  components: {
    PaymentKakaoPay
  },
};
</script>

<style lang="scss">
.page.add-payment-kakaopay {
}
</style>
