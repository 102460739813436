<template>
  <div class="resource payment-kakaopay">
    <Loader type="basic" :size="0.75" v-if="loading" />

    <p>{{ displayMessage }}</p>
    <p v-if="error" class="error">{{ errorMessage }}</p>
  </div>
</template>

<script>
import Loader from "@/v3/components/common/loader/Loader.vue";

export default {
  components: {
    Loader,
  },
  computed: {
    displayMessage() {
      let localeKey = "처리중 입니다.";

      switch (this.status) {
        case "READY":
          localeKey = "카카오페이로 연결 중이에요.";
          break;
        case "CANCEL":
          localeKey = "카카오 페이 연동이 취소 되었어요.";
          break;
        case "ERROR":
          localeKey = "카카오 페이 연동 중 오류가 생겼어요.";
          break;
        default:
          break;
      }

      return this.$__t(localeKey);
    },

    errorMessage() {
      return this.$__t(this.error);
    },
  },
  methods: {
    getRedirectWebUrl(data) {
      if (
        this.$store.state.config.mode === "debug" ||
        this.$store.state.config.mode === "development"
      ) {
        if (this.$store.getters.isTouchDevice) {
          return data.next_redirect_mobile_url;
        }

        return data.next_redirect_pc_url;
      }

      return data.next_redirect_app_url;
    },

    getFallbackUrls() {
      // if (this.wni.isNative) {
      //   return {
      //     approval_url: `${this.$store.state.config.appURL}/?link=${encodeURIComponent(`${this.$store.state.config.appURL}/payment/add/kakaopay/success`)}`,
      //     fail_url: `${this.$store.state.config.appURL}/?link=${encodeURIComponent(`${this.$store.state.config.appURL}/payment/add/kakaopay/fail`)}`,
      //     cancel_url: `${this.$store.state.config.appURL}/?link=${encodeURIComponent(`${this.$store.state.config.appURL}/payment/add/kakaopay/cancel`)}`,
      //   };
      // }

      return {
        approval_url: `${this.$store.state.config.siteURL}/payment/add/kakaopay/success`,
        fail_url: `${this.$store.state.config.siteURL}/payment/add/kakaopay/fail`,
        cancel_url: `${this.$store.state.config.siteURL}/payment/add/kakaopay/cancel`,
      };
    },

    async onReady() {
      if (this.status === "READY") {
        return;
      }

      this.loading = true;
      this.error = "";
      this.$data.status = "READY";

      const payload = this.getFallbackUrls();
      const data = await this.$store.dispatch("billings/kakaopay_ready", payload);
      const extras = data.extras;

      this.redirectToOpenKakaoPay(extras);
    },

    async onSuccess() {
      this.loading = true;
      try {
        this.$data.status = "SUCCESS";

        const { pg_token } = this.$route.query;
        const data = await this.$store.dispatch("billings/kakaopay_approve", {
          pg_token: pg_token,
          is_primary: 1,
        });

        await this.$store.dispatch("billings/getCardList", {
          query: {
            page: 1,
            size: 10,
          },
        });

        this.$data.status = data.status;

        if (data.status === "AUTHORIZED") {
          this.redirectToBack();
        } else {
          this.loading = false;
        }
      } catch (e) {
        console.error(e);
        this.onError(e.message);
        return;
      }
    },
    onCancel() {
      this.loading = true;
      this.$data.status = "CANCEL";

      this.redirectToBack();
    },
    onError(error) {
      this.$data.status = "ERROR";
      this.error = error;
      this.loading = false;
      // this.redirectToBack();
    },
    redirectToOpenKakaoPay(data) {
      setTimeout(() => {
        const link = this.getRedirectWebUrl(data);
        location.replace(link);
      }, 500);
    },
    redirectToBack() {
      setTimeout(() => {
        if (this.wni.isAndroid) {
          this.$router.go(-4);
        } else {
          this.$router.go(-2);
        }
      }, 500);
    },
  },

  data() {
    return {
      loading: false,
      status: "UNSET",
      error: "",
    };
  },

  async mounted() {
    if (this.$route.params.state) {
      if (this.$route.params.state === "success") {
        await this.onSuccess();
      } else if (this.$route.params.state === "cancel") {
        await this.onCancel();
      } else if (this.$route.params.state === "error") {
        await this.onError();
      }
    } else {
      if (!this.$route.params.state) {
        await this.onReady();
      }
    }
  },
};
</script>

<style lang="scss">
.resource.payment-kakaopay {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loading {
  }

  p {
    margin-top: unit(32);
  }
}
</style>
